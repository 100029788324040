import { ReactNode } from 'react';

export enum ButtonType {
  outlined = 'outlined',
  contained = 'contained',
}

type ButtonWhite = {
  white?: true;
  black?: never;
};

type ButtonBlack = {
  white?: never;
  black?: true;
};

type ButtonLink = {
  onClick?: never;
  url?: string;
};

type ClickableButton = {
  onClick?: VoidFunction;
  url?: never;
};

export type ButtonProps = {
  component?: 'a' | 'button';
  children: ReactNode;
  type?: ButtonType;
  target?: string;
  rel?: string;
  className?: string;
  icon?: ReactNode;
} & (ButtonLink | ClickableButton) &
  (ButtonWhite | ButtonBlack);
