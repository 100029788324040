import clsx from 'clsx';
import React from 'react';

import { ButtonProps, ButtonType } from 'shared/button/Button.types';

import classes from './Button.module.scss';

export const Button = ({
  component = 'a',
  children,
  type,
  url,
  onClick,
  target,
  className,
  white,
  black,
  icon,
  rel,
}: ButtonProps) => {
  if (component === 'button') {
    return (
      <div
        tabIndex={0}
        onKeyDown={() => {}}
        role="button"
        onClick={onClick}
        className={clsx(
          type === ButtonType.outlined && classes.outlined,
          classes.button,
          white && classes.white,
          black && classes.black,
          className,
        )}
      >
        <div className={classes.content}>{children}</div>
        {icon && <div className={classes.icon}>{icon}</div>}
      </div>
    );
  }

  return (
    <a
      onClick={onClick}
      href={url}
      target={target}
      rel={rel}
      className={clsx(
        type === ButtonType.outlined && classes.outlined,
        classes.button,
        white && classes.white,
        black && classes.black,
        className,
      )}
    >
      <div className={classes.content}>{children}</div>
      {icon && <div className={classes.icon}>{icon}</div>}
    </a>
  );
};
