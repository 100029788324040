import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faXTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import { MenuItem } from 'shared/footer/Footer.types';
import {
  STAGECLIP_CONTACT_US_URL,
  STAGECLIP_COOKIE_STATEMENT_URL,
  STAGECLIP_COPYRIGHT_REPORTING_URL,
  STAGECLIP_PRIVACY_POLICY_URL,
  STAGECLIP_TERMS_AND_CONDITIONS_URL,
} from 'utils/constants';

import classes from './Footer.module.scss';

const MENU_ITEMS: MenuItem[] = [
  { name: 'Privacy Policy', url: STAGECLIP_PRIVACY_POLICY_URL },
  { name: 'Terms & conditions', url: STAGECLIP_TERMS_AND_CONDITIONS_URL },
  { name: 'Cookie Statement', url: STAGECLIP_COOKIE_STATEMENT_URL },
  { name: 'Contact us', url: STAGECLIP_CONTACT_US_URL },
  { name: 'Copyright Reporting', url: STAGECLIP_COPYRIGHT_REPORTING_URL },
];

export const Footer = () => (
  <footer className={classes.footer}>
    <div className="fullColumn">
      <div className={classes.footerWrapper}>
        <div className={classes.footerMenu}>
          {MENU_ITEMS.map((menuItem) => (
            <a
              key={menuItem.name}
              className={classes.menuItem}
              aria-label={`View ${menuItem.name}`}
              href={menuItem.url}
              target="_blank"
              rel="noreferrer"
            >
              {menuItem.name}
            </a>
          ))}
        </div>
        <div className={classes.socialMedia}>
          <a
            href="https://www.instagram.com/stageclip_official/"
            className={clsx([classes.socialMediaIcon, 'fa-layers', 'fa-fw', 'fa-2x'])}
            aria-label="View StageClip Instagram"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram as IconProp} inverse />
          </a>
          <a
            href="https://www.linkedin.com/company/stageclip/"
            className={clsx([classes.socialMediaIcon, 'fa-layers', 'fa-fw', 'fa-2x'])}
            aria-label="View StageClip Linkedin"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn as IconProp} inverse />
          </a>
          <a
            href="https://facebook.com/stageclip"
            className={clsx([classes.socialMediaIcon, 'fa-layers', 'fa-fw', 'fa-2x'])}
            aria-label="View StageClip Facebook"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF as IconProp} inverse />
          </a>
          <a
            href="https://twitter.com/stageclip"
            className={clsx([classes.socialMediaIcon, 'fa-layers', 'fa-fw', 'fa-2x'])}
            aria-label="View StageClip Twitter"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faXTwitter as IconProp} inverse />
          </a>
        </div>
      </div>
      <div className={classes.copyright}>&copy; {new Date().getFullYear()} StageClip Ltd.</div>
    </div>
  </footer>
);
