import { useCombobox } from 'downshift';
import React, { useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import { useDebounce } from 'use-debounce';

import { fetchClips } from 'api/clip/fetchClips';
import { generateTitle } from 'homePage/search/Search.utils';

import { Search } from './Search';
import { SearchContainerProps } from './Search.types';

const MIN_CHAR_NUMBER_TO_SEARCH = 3;
const inputId = 'search-input';
const DEBOUNCE_SEARCH_TIME = 200;

export const SearchContainer = ({ domain, color, isLargeMenu, isAllowCollctiv }: SearchContainerProps) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_SEARCH_TIME);

  const { isLoading, resolvedData, latestData } = usePaginatedQuery(
    ['fetchClips', { debouncedSearch }],
    (key, { debouncedSearch }) =>
      fetchClips({
        searchText: debouncedSearch,
        domain,
      }),
    {
      enabled: debouncedSearch.length >= MIN_CHAR_NUMBER_TO_SEARCH,
    },
  );

  const clips = latestData || resolvedData || { participants: [], ceremonies: [] };

  const getMenuItems = () => {
    const items: string[] = [];

    Object.entries(clips).map(
      ([key, value]) =>
        key === 'participants' &&
        (value as []).map(({ fullName, subtitle, secondSubtitle, date }) =>
          items.push(generateTitle({ fullName, subtitle, secondSubtitle, date })),
        ),
    );
    Object.entries(clips).map(
      ([key, value]) =>
        key === 'ceremonies' &&
        (value as []).map(({ fullName, subtitle, secondSubtitle, date }) =>
          items.push(generateTitle({ fullName, subtitle, secondSubtitle, date })),
        ),
    );

    return items;
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    openMenu,
    inputValue,
    highlightedIndex,
  } = useCombobox({
    items: getMenuItems(),
    inputValue: search,
    id: 'search-clip',
    inputId: inputId,
    labelId: 'search-label',
    menuId: 'search-menu',
  });

  const areResults = !!clips?.participants?.length || !!clips?.ceremonies?.length;
  const isSearching = search.length >= MIN_CHAR_NUMBER_TO_SEARCH;
  const isInitialSearch = search !== debouncedSearch && search.length === MIN_CHAR_NUMBER_TO_SEARCH && !areResults;
  const shouldOpenMenu = isOpen && !isInitialSearch && isSearching && !isLoading;

  const onFocus = () => {
    if (inputValue.length >= MIN_CHAR_NUMBER_TO_SEARCH) {
      openMenu();
    }
  };

  return (
    <Search
      search={search}
      setSearch={setSearch}
      clips={clips}
      isLoading={isLoading}
      shouldOpenMenu={shouldOpenMenu}
      onFocus={onFocus}
      highlightedIndex={highlightedIndex}
      areResults={areResults}
      getComboboxProps={getComboboxProps}
      getMenuProps={getMenuProps}
      getInputProps={getInputProps}
      getItemProps={getItemProps}
      color={color}
      isLargeMenu={isLargeMenu}
      onItemClick={() => setSearch('')}
      isAllowCollctiv={isAllowCollctiv}
    />
  );
};
