import React from 'react';

import { Button } from 'shared/button/Button';
import { ButtonType } from 'shared/button/Button.types';
import { ContactButtonProps } from 'shared/contactButton/ContactButton.types';
import { STAGECLIP_CONTACT_US_URL } from 'utils/constants';

export const ContactButton = ({ className }: ContactButtonProps) => (
  <Button
    component="a"
    className={className}
    type={ButtonType.outlined}
    url={STAGECLIP_CONTACT_US_URL}
    target="_blank"
    rel="noreferrer"
  >
    Contact us
  </Button>
);
