/* eslint-disable react/no-unescaped-entities */
import { useState, useEffect } from 'react';

import styles from './CookieBanner.module.scss';

export const CookieBanner = () => {
  const localStorageItemName = 'show-portals-terms';
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    setShowBanner(!(localStorage.getItem(localStorageItemName) === 'false'));
  }, []);

  useEffect(() => {
    localStorage.setItem(localStorageItemName, `${showBanner}`);
  }, [showBanner]);

  return showBanner ? (
    <div className={styles.siteCookieBanner}>
      <div className={styles.innerCookieBanner}>
        <span>
          We only use cookies to enhance your experience and for analytics purposes. By clicking "Accept", you agree to
          our use of cookies.
          <span>
            <button
              className={styles.closeCookie}
              onClick={() => setShowBanner(false)}
              data-testid="cookie-button"
              aria-label="Accept cookies and close cookie banner"
            >
              Accept
            </button>
          </span>
          <span>
            <button
              className={styles.rejectCookie}
              onClick={() => setShowBanner(false)}
              data-testid="cookie-button"
              aria-label="Reject cookies and close cookie banner"
            >
              Reject
            </button>
          </span>
          <span className={styles.privacyPolicy}>
            <a
              className={styles.innerCookieBannerMoreInfoLink}
              target="_blank"
              rel="noreferrer"
              href="https://stageclip.com/privacy-policy/"
              aria-label="View privacy policy"
            >
              Privacy policy.
            </a>
          </span>
        </span>
      </div>
    </div>
  ) : null;
};
