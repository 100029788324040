import { format } from 'date-fns';

export const generateTitle = ({
  date,
  fullName,
  subtitle,
  secondSubtitle,
}: {
  fullName: string;
  date?: string;
  subtitle?: string;
  secondSubtitle?: string;
}) =>
  `${fullName}${subtitle ? ` — ${subtitle}` : ''}${secondSubtitle ? ` — ${secondSubtitle}` : ''}${
    date ? ` ( ${format(new Date(date), 'LLLL yyyy')} )` : ''
  }`;

export const getClipName = (clipVideoUri: string | undefined) =>
  clipVideoUri?.match(/.*\/(clip|product|ceremony)\/(?<clipName>.*?)\/?$/)?.groups?.clipName;
