import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { ClipPurchaseType } from 'models/portal.model';
import { CookieBanner } from 'shared/cookieBanner/CookieBanner';
import { Footer } from 'shared/footer/Footer';
import { Header } from 'shared/header/Header';

import styles from './SiteLayout.module.scss';
import { SiteLayoutProps } from './SiteLayout.types';

export const SiteLayout = ({
  children,
  color,
  domain,
  clipSearchEnabled,
  clip,
  isAllowCollctiv,
  config,
  isAllowForAmazonMusic,
}: SiteLayoutProps) => {
  const router = useRouter();
  const [isEmbeddedInclude, setIsEmbeddedInclude] = useState(false);

  useEffect(() => {
    const authResult = new URLSearchParams(window.location.search);

    const isEmbedded = authResult.get('embedded') === 'true';
    setIsEmbeddedInclude(isEmbedded);
  }, []);

  return (
    <div className={styles.wrapper}>
      {isEmbeddedInclude ? null : config?.clipPurchaseType === null ? null : (isAllowCollctiv ||
          isAllowForAmazonMusic ||
          !isAllowCollctiv ||
          !isAllowForAmazonMusic) &&
        config?.clipPurchaseType !== ClipPurchaseType.RetailEndUserPays ? null : (router.asPath.includes('clip/') &&
          !!clip?.uri) ||
        (router.asPath.includes('clip/') && !!clip?.uri && !isAllowCollctiv) ||
        (router.asPath.includes('clip/') &&
          !!clip?.uri &&
          isAllowCollctiv &&
          config?.clipPurchaseType === ClipPurchaseType.RetailEndUserPays) ||
        router.asPath === '/' ? (
        <Header color={color} domain={domain} clipSearchEnabled={clipSearchEnabled} />
      ) : null}
      <div className={styles.content}>{children}</div>
      {isEmbeddedInclude ? null : <CookieBanner />}
      {isEmbeddedInclude ? null : <Footer />}
    </div>
  );
};
