import axios from 'axios';

import { cacheableApi } from 'api';
import { PortalConfigurationViewModel } from 'models/portal.model';
import { CacheManager } from 'utils/cache.manager';

const fetchPortal = async (cache: CacheManager, domain: string): Promise<PortalConfigurationViewModel> => {
  const getPath = `/portals/${domain}/configuration`;

  return cacheableApi<PortalConfigurationViewModel>(cache, domain, getPath);
};

export const fetchPortalViaProxy = async (): Promise<{ data: PortalConfigurationViewModel }> =>
  axios.get('/api/portal');

const fetchPortalData = {
  fetchPortal,
};

export default fetchPortalData;
