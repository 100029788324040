import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { useState } from 'react';

import { useWindowSize } from 'utils/hooks/useWindowResize';

import classes from './Search.module.scss';
import { SearchProps } from './Search.types';
import { generateTitle, getClipName } from './Search.utils';
import { SearchInput } from './searchInput/SearchInput';
import { SearchItem } from './searchItem/SearchItem';

const BREAKPOINT_TABLET_SMALL = 768;

export const Search = ({
  clips,
  search,
  setSearch,
  isLoading,
  shouldOpenMenu,
  onFocus,
  highlightedIndex,
  areResults,
  getComboboxProps,
  getMenuProps,
  getInputProps,
  getItemProps,
  color,
  isLargeMenu,
  onItemClick,
  isAllowCollctiv,
}: SearchProps) => {
  const searchContainerStyles = clsx(classes.searchContainer, { [classes.processing]: isLoading });
  const [windowWidth] = useWindowSize();

  const listStyles = clsx(
    classes.list,
    isLargeMenu && shouldOpenMenu && classes.largeMenu,
    shouldOpenMenu && classes.isOpen,
    {
      [classes.listOpen]: shouldOpenMenu,
    },
  );

  const [isOnMobileOpen, setOnMobileOpen] = useState(windowWidth <= BREAKPOINT_TABLET_SMALL);

  return (
    <div className={classes.container}>
      <form role="search" className={clsx(classes.form, isLargeMenu && shouldOpenMenu && classes.largeMenuForm)}>
        {!isOnMobileOpen && windowWidth <= BREAKPOINT_TABLET_SMALL && (
          <div {...getComboboxProps({ className: searchContainerStyles })}>
            <SearchInput
              getInputProps={getInputProps}
              search={search}
              onChange={setSearch}
              onFocus={onFocus}
              color={color}
            />
          </div>
        )}
        {(windowWidth >= BREAKPOINT_TABLET_SMALL || !isLargeMenu) && (
          <div {...getComboboxProps({ className: searchContainerStyles })}>
            <SearchInput
              getInputProps={getInputProps}
              search={search}
              onChange={setSearch}
              onFocus={onFocus}
              color={color}
            />
          </div>
        )}
        {!isOnMobileOpen && windowWidth <= BREAKPOINT_TABLET_SMALL ? (
          <div
            className={clsx(
              isAllowCollctiv ? classes.collctivClose : classes.close,
              shouldOpenMenu && classes.closePortalColor,
            )}
            onClick={() => setOnMobileOpen((v) => !v)}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            ✕
          </div>
        ) : (
          <div
            className={classes.searchButton}
            onClick={() => setOnMobileOpen((v) => !v)}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <FontAwesomeIcon style={{ color: isAllowCollctiv ? color : '' }} icon={faSearch} inverse />
          </div>
        )}
      </form>
      <ul {...getMenuProps({ 'aria-label': 'listbox' })} className={listStyles}>
        {areResults &&
          clips &&
          shouldOpenMenu &&
          Object.entries(clips).map(([key, value]) =>
            (value as []).map(
              ({ fullName, subtitle, clipPageUri, secondSubtitle, date, ceremonyPageUri }, resultIndex) => (
                <>
                  {resultIndex === 0 && (
                    <li className={classes.listItem}>
                      <div>{key}</div>
                    </li>
                  )}
                  <SearchItem
                    key={`${generateTitle({ fullName, subtitle, secondSubtitle, date })} ${resultIndex}`}
                    title={generateTitle({ fullName, subtitle, secondSubtitle, date })}
                    highlightedIndex={highlightedIndex}
                    index={resultIndex}
                    getItemProps={getItemProps}
                    searchText={search}
                    clipPageUri={getClipName(clipPageUri ?? ceremonyPageUri)}
                    onItemClick={onItemClick}
                    searchGroupName={key}
                  />
                </>
              ),
            ),
          )}
        {!areResults && shouldOpenMenu && (
          <li className={classes.noResultsText}>
            Unable to find your clip? Check our{' '}
            <a
              href="https://stageclip.freshdesk.com/support/solutions/folders/33000208713"
              target="_blank"
              rel="noreferrer"
              aria-label="View FAQ"
            >
              FAQ
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};
