import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';

import { SearchContainer } from 'homePage/search/SearchContainer';
// import { CartButton } from 'shared/cartButton/CartButton';
import { ContactButton } from 'shared/contactButton/ContactButton';
import { HeaderProps } from 'shared/header/Header.types';

import classes from './Header.module.scss';

export const Header = ({ color, domain, clipSearchEnabled }: HeaderProps) => {
  const router = useRouter();

  return (
    <header className={classes.siteHeader} style={{ background: color }}>
      <div className={clsx(['fullColumn', classes.innerHeader])}>
        <a className={classes.logoContainer} href="/" aria-label="View home page">
          <img src="/logo.svg" alt="StageClip logo" />
        </a>
        {router?.pathname.includes('clip') || router?.pathname.includes('ceremony') ? (
          <div className={classes.search}>
            <div className={clsx(classes.searchWrapper)}>
              {clipSearchEnabled && (
                <SearchContainer domain={domain} color={color} isLargeMenu isAllowCollctiv={false} />
              )}
            </div>
            <ContactButton className={classes.navButton} />
            {/* <CartButton className={classes.cartButton} /> */}
          </div>
        ) : (
          <ContactButton className={classes.navButton} />
        )}
      </div>
    </header>
  );
};
