import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import Highlighter from 'react-highlight-words';

import classes from './SearchItem.module.scss';
import { SearchItemProps } from './SearchItem.types';
import { getPath } from './SearchItem.utils';

export const SearchItem = ({
  title,
  highlightedIndex,
  index,
  getItemProps,
  searchText,
  clipPageUri,
  onItemClick,
  searchGroupName,
}: SearchItemProps) => {
  const itemStyles = (index: number) => clsx(classes.listItem, { [classes.highlighted]: highlightedIndex === index });

  const hrefPath =
    searchGroupName === 'ceremonies'
      ? { path: '/ceremony/[...ceremonyPageUri]', clipPageUri }
      : { path: '/clip/[...clipPageUri]', clipPageUri };
  const asPath =
    searchGroupName === 'ceremonies'
      ? { path: `/ceremony/${clipPageUri}`, clipPageUri }
      : { path: `/clip/${clipPageUri}`, clipPageUri };

  return (
    <li
      className={itemStyles(index)}
      key={`${title}${index}`}
      {...getItemProps({ item: title, index, role: 'presentation' })}
    >
      <div className={classes.linkWrapper} onClick={onItemClick} onKeyDown={() => {}} role="button" tabIndex={0}>
        <Link href={getPath(hrefPath)} as={getPath(asPath)}>
          <p>
            <Highlighter
              searchWords={[searchText]}
              textToHighlight={title}
              highlightTag="strong"
              className={classes.listItemText}
            />
          </p>
        </Link>
      </div>
    </li>
  );
};
